import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Toolbar,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  Container,
  Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../images/karch_logo.png";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useTheme } from "@material-ui/styles";
import * as constants from "../constants";
import API from "../utils/API";
import KaSeo from "./shared/KaSeo";
import useScreensize from "../hooks/useScreensize";
import MenuUpIcon from "mdi-react/MenuUpIcon";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import Beratungstermin from "./shared/Beratungstermin";
import ModalBeratungstermin from "./ModalBeratungstermin";

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      flexShrink: 0,
      zIndex: 9999999
    }
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    zIndex: 999
  },
  menuButton: {
    color: blueGrey[800],
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 9999,
    width: drawerWidth,
    background: "white"
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  logo: {
    height: "180%",
    width: "auto"
  },
  logoWrapper: {
    height: "100%",
    marginRight: theme.spacing(4),
    padding: theme.spacing(1)
  },
  appBarMenu: {
    flexGrow: 1
  },
  tb: {
    height: 1
  },
  btnBeratung: {
    marginRight: "15px"
  },
  desktopMenuIcon: {
    marginLeft: "auto",
    marginRight: theme.spacing(2)
  },
  popover: {
    zIndex: 99999999,
    marginTop: theme.spacing(3)
  },
  popoverPaper: {
    background: "white",
    minWidth: 600
  },
  menuUpIcon: {
    position: "absolute",
    right: 0,
    top: -30,
    width: 50,
    height: 50,
    color: "white"
  },
  menuImage: {
    backgroundSize: "cover"
  },
  menuItem: {
    borderRadius: 4
  },
  activeMenuItem: {
    background: grey[100]
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const [settings, setSettings] = useState(null);
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const { xs, sm, md, lg, xl } = useScreensize();
  const [activePath, setActivePath] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  useEffect(() => {
    async function fetchData() {
      try {
        const resultData = await API.post(`/singletons/get/WebsiteSettings`);
        const data = resultData.data ? resultData.data : {};
        setSettings(data);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDesktopClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDesktopOpen(false);
  };

  const menu = Boolean(settings) && (
    <MenuList>
      {settings.main_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.value.url === activePath
          })}
          button
          key={index}
          component={Link}
          to={item.value.url}
          onClick={event => {
            setMobileOpen(false);
            handleDesktopClose(event);
          }}
        >
          <Box display="flex" py={1} alignItems="center">
            <Box
              mr={2}
              borderRadius={4}
              flexShrink={0}
              className={classes.menuImage}
              width={xs ? 40 : 80}
              height={xs ? 40 : 80}
              style={{
                backgroundImage:
                  "url(" +
                  constants.BACKEND_URL +
                  "/storage/uploads" +
                  item.value.image.path +
                  ")"
              }}
            ></Box>
            <Box flexGrow={1} p={1} lineHeight={1.3}>
              <strong>{item.value.title}</strong>
              <br />
              {item.value.subtitle}
            </Box>
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  );

  const secondaryMenu = Boolean(settings) && (
    <MenuList>
      {settings.secondary_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.value.url === activePath
          })}
          button
          key={index}
          component={Link}
          to={item.value.url}
          onClick={event => {
            setMobileOpen(false);
            handleDesktopClose(event);
          }}
        >
          {item.value.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const drawer = Boolean(settings) && (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {menu}
      <Divider />
      {secondaryMenu}
    </div>
  );

  const seo = Boolean(settings) && (
    <KaSeo title={settings.title} description={settings.description} />
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: "white" }}
        className={classes.appBar}
      >
        <Toolbar className={classes.tb}>
          <Container maxWidth="xl">
            <Box display="flex" height={xs ? 56 : 64} alignItems="center">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Link className={classes.logoWrapper} to="/">
                <img
                  src={Logo}
                  className={classes.logo}
                  alt="Möbel Karch Kaiserslautern"
                />
              </Link>
              <Hidden smDown>
                <IconButton
                  className={classes.desktopMenuIcon}
                  ref={anchorRef}
                  onClick={() => {
                    setDesktopOpen(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>

              <Beratungstermin className={classes.btnBeratung} />
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      {desktopOpen && (
        <Popper
          className={classes.popover}
          open={desktopOpen}
          anchorEl={anchorRef.current}
          keepMounted
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} in={desktopOpen}>
              <Paper id="menu-list-grow" className={classes.popoverPaper}>
                <MenuUpIcon className={classes.menuUpIcon} />
                <ClickAwayListener onClickAway={handleDesktopClose}>
                  <Box display="flex" p={4}>
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" mr={3}>
                      {menu || <></>}
                    </Box>
                    <Box width="1px" bgcolor={grey[200]} mr={3} />
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" px={2}>
                      {secondaryMenu || <></>}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      <Footer settings={settings} />
      {/* <ModalBeratungstermin /> */}
      {seo}
    </div>
  );
};

export default Layout;
